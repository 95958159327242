.bankModalView {
    padding: 24px 32px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.stBnkWidth {
    width: 100%;
    max-width: 600px;
}

.bankModalView .createFormLInput {
    font-size: 14px;
    padding: 12px 24px;
    color: #9C9C9C;
}

.bankModalView .mb35 {
    margin-bottom: 16px;
}

.bankModalView p.mb-1 {
    margin-bottom: 8px !important;
    color: #898A8D;
}

.bankModalView .cardd {
    background-size: 5%;
}

.bankModalView h4 {
    margin-bottom: 14px;
}

.connectsModalDiv {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
    border-radius: 20px;
    padding: 24px 29px;
    width: 100%;
    max-width: 480px;
}

.modelButtn {
    background: #82D051;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    width: 111px;
    height: 39px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    margin-top: 24px;
}

.connectsModalDiv>p {
    color: #14142B;
    font-weight: 600;
}

.connectM {
    display: flex;
}

.connectM .classicalBtn {
    color: #9C9C9C;
}

.classicalWidth {
    width: 114px;
}

.connectsModalDiv .set2ndBox {
    padding: unset;
}

.addConnctsP {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #82D051 !important;
    margin-top: 18px;
    margin-bottom: 12px;
}

@media(max-width:575px) {

    .bankModalView {
        padding: 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
    }

    .p {
        font-size: 13px;
        line-height: 18px;
    }

    .bankModalView h4 {
        margin-bottom: -10px;
    }

    .bankModalView .createFormLInput {
        width: 100%;
    }
}