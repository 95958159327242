/* Popup style */
.popup__popup-box {
  position: fixed;
  background: #00000050;
  z-index: 999999999999999999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
 
.popup__box {
  width: 70%;
  height: auto;
  max-height: 70vh;
  background: #fff;
  border-radius: 4px;
  padding: 35px;
  border: 1px solid #999;
  overflow: auto;
}
 
.popup__close-icon {
  content: 'x';
  cursor: pointer;
  text-align: right;
  margin-left: auto;
  line-height: 20px;
  font-size: 20px;
  color: #C4C4C4;
}

.popper__mainHeading{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}
.popper__body{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #2B2F30;
}

.popper__btnAction button{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 26px;
  padding: 10px 22px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.popper__btnAction .popper__accept{
  background: #82D051;
  color: #FFFFFF;

  margin-right: 15px;
}

.popper__btnAction .popper__out{
  background: #FFFFFF;
  border: 1px solid #9C9C9C;
  color: #2B2F30;
}